import React from 'react';
import { Button, Card, Typography } from 'antd/lib';
import Image from 'next/image';

const innovataursImg = '/images/innovataurs.png';

const { Title, Paragraph } = Typography;

export const InnovataursCard = () => (
  <Card className="innovataurs-card" bodyStyle={{ padding: 10 }}>
    <div className="innovataurs-card-content">
      <div className="innovataurs-card-image">
        <Image
          src={innovataursImg}
          alt="innovataurs"
          width={250}
          height={250}
        />
      </div>

      <div className="innovataurs-card-text-container">
        <Title level={4} className="innovataurs-card-title">
          Are you an Innovataur?
        </Title>
        <Paragraph className="innovataurs-card-text">
          Join a pioneering group of early Centaurs users. Get early feature
          updates, meet other pioneers and provide feedback to shape the product
          around your needs.
        </Paragraph>
        <a
          href="https://t.me/+IUnSDNblV6E0MTI0"
          target="_blank"
          rel="noreferrer"
        >
          <Button type="default">Join the Innovataurs</Button>
        </a>
      </div>
    </div>
  </Card>
);

import { toLower } from 'lodash';
import { notification } from 'antd/lib';
import { GATEWAY_URL } from 'util/constants';

export const notifyError = (message = 'Some error occured') => notification.error({
  message,
});

export const notifySuccess = (message = 'Successfull', description = null) => notification.success({
  message,
  description,
});

export const isGoerli = (id) => id === 5;

export const areAddressesEqual = (a1, a2) => toLower(a1) === toLower(a2);

export const getUuid = () => {
  const uuid = Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
  return uuid;
};

// TODO: move to autonolas library
/**
 * returns hash from the url
 * @example
 * input: router-path (for example, /components#my-components)
 * output: my-components
 */
export const getHash = (router) => router?.asPath?.split('#')[1] || '';

export const isChatTab = (hash) => !!(hash || '').includes('chat');

export const isDevOrStaging = process.env.NODE_ENV === 'development'
  || process.env.NODE_VERCEL_ENV === 'staging';

export const getIpfsResponse = async (hash) => {
  try {
    const ipfsUrl = `${GATEWAY_URL}f01701220${hash.substring(2)}`;
    const response = await fetch(ipfsUrl);
    const json = await response.json();
    return json;
  } catch (e) {
    window.console.error('Error fetching metadata from IPFS', e);
    throw new Error(e);
  }
};
